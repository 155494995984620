if (typeof Element !== "undefined" && !Element.prototype.matches) {
  Element.prototype.matches =
    (Element as any).prototype.msMatchesSelector ||
    (Element as any).prototype.webkitMatchesSelector
}

if (typeof Element !== "undefined" && !Element.prototype.closest) {
  ;(Element as any).prototype.closest = function(s) {
    let el = this

    do {
      if (el.matches(s)) {
        return el
      }
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}
