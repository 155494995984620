import React from "react"
import ReactDOM from "react-dom"

import Follower from "../helpers/follower"
import isTouchDevice from "../helpers/isTouchDevice"
import styles from "./main_layout.module.scss"

require("../helpers/closest")
try {
  require("whatwg-fetch")
} catch (e) {}

class MainLayout extends React.Component {
  componentDidMount() {
    if (!isTouchDevice()) {
      if (!document.getElementById("follower")) {
        this.appendFollower()
      }
      const follow = new Follower()
      const style = document.createElement("style")
      style.id = "follower-style"
      document.head.appendChild(style)
    } else {
      if (!document.body.classList.contains("is-touch-device")) {
        document.body.classList.add("is-touch-device")
      }
    }
  }

  appendFollower() {
    const follower = document.createElement("div")
    const followerText = document.createElement("span")
    followerText.className = "follower-text"
    follower.id = "follower"
    follower.appendChild(followerText)
    document.body.appendChild(follower)
  }

  render() {
    return <div className={styles.application}>{this.props.children}</div>
  }
}

export default MainLayout
