export default function Follower() {
  let init
  const follower = document.getElementById("follower")

  const mouseX = (event) => {
    return event.clientX
  }

  const mouseY = (event) => {
    return event.clientY
  }

  const positionElement = (event) => {
    let mouse
    mouse = {
      x: mouseX(event),
      y: mouseY(event),
    }
    if (follower) {
      follower.style.top = mouse.y + "px"
      return (follower.style.left = mouse.x + "px")
    }
  }

  let timer

  window.onmousemove = init = (event) => {
    // tslint:disable-next-line:variable-name
    let _event
    _event = event
    return (timer = setTimeout(() => {
      return positionElement(_event)
    }, 1))
  }
}
if (typeof document !== "undefined") {
  const resetFollow = () => {
    const follower = document.getElementById("follower")
    if (follower && follower.classList.contains("focused")) {
      const text: any = follower.querySelector(".follower-text")
      text.innerHTML = ""
      follower.style.width = ""
      follower.style.height = ""
      follower.classList.remove("focused")
      follower.classList.remove("focused-on-content")
      follower.classList.remove("focused-on-text")
      follower.classList.remove("follower-icon")
      follower.style.backgroundColor = ""
      follower.style.borderColor = ""
    }
  }

  const handleFollow = (e) => {
    if (e && e.target && e.target.closest(".hover-target")) {
      const element = e.target.closest(".hover-target")
      const follower = document.getElementById("follower")

      if (follower) {
        const text: any = follower.querySelector(".follower-text")
        if (
          text &&
          element.dataset.hover &&
          !element.classList.contains("swiper-button-disabled")
        ) {
          text.innerHTML = element.dataset.hover
          const width = text.offsetWidth
          const height = text.offsetHeight
          const value = width > height ? width : height
          follower.style.width = value + "px"
          follower.style.height = value + "px"
        }

        if (!element.classList.contains("swiper-button-disabled")) {
          if (element.dataset.hoverBackground) {
            follower.style.backgroundColor = element.dataset.hoverBackground
            follower.style.borderColor = element.dataset.hoverBackground
          }
          if (element.classList.contains("hover-target-text-link")) {
            follower.classList.add("focused")
            follower.classList.add("focused-on-text")
          } else if (element.classList.contains("hover-icon")) {
            follower.classList.add("follower-icon")
            follower.classList.add("focused")
          } else {
            follower.classList.add("focused")
            follower.classList.add("focused-on-content")
          }
        } else {
          resetFollow()
        }
      }
    } else {
      resetFollow()
    }
  }
  document.addEventListener("mousemove", (e: any) => handleFollow(e))
}
